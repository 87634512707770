<template>
  <footer>
    <p>@2021GoodIdeas.All rights reserved</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="scss" scoped>
footer {
  p {
    color: color.$secondary;
    padding: 20px 0;
    text-align: center;
    font-weight: 900;
  }
  background: #fff;
}
</style>
